import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex" }
const _hoisted_2 = { class: "w-max flex h-full" }
const _hoisted_3 = { class: "w-16 h-full bg-gray-100 flex flex-col px-2 items-center py-3 space-y-5" }
const _hoisted_4 = { type: "button" }
const _hoisted_5 = {
  key: 0,
  class: "w-96 h-full bg-gray-50"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "max-h-full overflow-y-auto"
}
const _hoisted_8 = { class: "bg-gray-400 w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_AddLocations = _resolveComponent("AddLocations")!
  const _component_ResultsMenu = _resolveComponent("ResultsMenu")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        class: "h-full",
        fullscreen: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShowSearchInputs && _ctx.toggleShowSearchInputs(...args))),
                  type: "button"
                }, [
                  _createVNode(_component_ion_icon, {
                    class: "w-12 h-12 text-gray-600",
                    icon: _ctx.searchOutline,
                    size: "md"
                  }, null, 8, ["icon"])
                ]),
                _createElementVNode("button", _hoisted_4, [
                  _createVNode(_component_ion_icon, {
                    class: "w-12 h-12 text-gray-600",
                    icon: _ctx.timeOutline,
                    size: "md"
                  }, null, 8, ["icon"])
                ])
              ]),
              (_ctx.showSearchInputs || _ctx.showResults)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_ctx.showSearchInputs)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_AddLocations)
                        ]))
                      : (_ctx.showResults)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_ResultsMenu)
                          ]))
                        : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_GoogleMap)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
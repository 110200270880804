import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex space-x-3 pl-2 py-1.5" }
const _hoisted_2 = { class: "flex items-center rounded-full p-1 bg-gray-200" }
const _hoisted_3 = { class: "pl-1" }
const _hoisted_4 = { class: "flex items-center rounded-full p-1 bg-gray-200" }
const _hoisted_5 = { class: "pl-1" }
const _hoisted_6 = { class: "flex items-center rounded-full p-1 bg-gray-200" }
const _hoisted_7 = { class: "pl-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ion_icon, {
          class: "h-6 w-6",
          icon: _ctx.resizeOutline
        }, null, 8, ["icon"]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.radius/1000) + "km", 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ion_icon, {
          class: "h-6 w-6",
          icon: _ctx.timeOutline
        }, null, 8, ["icon"]),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.threshold/60) + "min", 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ion_icon, {
          class: "h-6 w-6",
          icon: _ctx.homeOutline
        }, null, 8, ["icon"]),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.type), 1)
      ])
    ])
  ]))
}
// export const baseUrl = 'http://localhost:8000/api';
export const baseUrl = 'https://api.meet-midway.de/api';
export const logging = true;

// 0 = google
// 1 = mapbox
const apis = {
    0: 'google',
    1: 'mapbox',
}

export const geocodeApi = apis[1];
export const directionsApi = apis[1];

export const distanceMatrixApi = apis[1];
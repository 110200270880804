import {baseUrl, geocodeApi, logging} from "@/settings";
import axios from "axios";

interface coords {
    lat: number,
    lng: number
}

export const geocodeAddress = async (address: string) => {
    let geocode = '';
    if (logging) console.log('%cgeocoding address with request to BE','color: green; font-family:monospace; font-weight: bold; font-size: 14px;  ', address)
    await axios.get(baseUrl + '/geocode?address='+encodeURIComponent(address))
        .then((response)=>{
            // @ts-ignore
            if (geocodeApi==='google'){
                if (logging) console.log(response.data["results"][0]);
                geocode = response.data["results"][0];
            }else{
                if (logging) console.log(response.data);
                geocode = response.data;
            }

        })
        .catch((error)=>{
            if (logging) console.error('error geocoding address: '+address,error);
        })
    return geocode;
}

export const reverseGeocode = async (coords: coords) => {
    let geocode = '';
    if (logging) console.log('%cgeocoding latlng with request to BE','color: green; font-family:monospace; font-weight: bold; font-size: 14px;  ', coords.lat+','+coords.lng)
    await axios.get(baseUrl + '/geocodeCoords?lat='+encodeURIComponent(coords.lat)+'&lng='+encodeURIComponent(coords.lng))
        .then((response)=>{
            // @ts-ignore
            if (geocodeApi==='google'){
                if (logging) console.log(response.data["results"][0]);
                geocode = response.data["results"][0];
            }else if (geocodeApi==='mapbox'){
                if (logging) console.log(response.data);
                geocode = response.data;
            }
        })
        .catch((error)=>{
            if (logging) console.error('error geocoding latlng: '+coords.lat+','+coords.lng,error);
        })
    return geocode;
}
import {baseUrl, distanceMatrixApi, logging} from "@/settings";
import {locations, midpointLocation} from "@/components/map/locations";
import {addLocationMarker, addRadiusCircle} from "@/components/map/map";
import axios from "axios";
import {getDistanceMatrix, getDistancesFromLocationsToClickedPlace} from "@/components/map/distances";
import {ref} from "vue";

const types_codepoints = {
    'lodging': '\ue53a',
    'cafe': '\ue541',
    'restaurant': '\ue56c',
    'bar': '\ue540',
    'meal_delivery': '\uea74',
    'meal_takeaway': '\uea74',
    'liquor_store': '\uea12',
    'bakery': '\uea53',
    'gas_station': '\ue546',
    'car_wash': '\ue546',
    'night_club': '\uea62',
    'convenience_store': '\ue543',
    'florist': '\ue545',
}
export const places = ref();
export const placesWithinThreshold = ref({});
export const clickedPlace = ref({place: undefined, distances: undefined});

export const resetPlaces = () => {
    if (logging) console.log('places',places.value);
    if (places.value !== undefined){
        const keysOfPlacesWithinThreshold = Object.keys(places.value);
        for (let i = 0; i < keysOfPlacesWithinThreshold.length; i++) {
            // @ts-ignore
            if (logging) console.log('removing marker',i)
            places.value[i]["marker"].visible = false;
            places.value[i]["marker"].setMap(null);
        }
        places.value=undefined;
    }
}
export const resetPlacesWithinThreshold = () => {
    placesWithinThreshold.value = {};
}
export const resetClickedPlace = () => {
    clickedPlace.value = {place: undefined, distances: undefined};
}

export const getNearbyMidpoint = async (radius: number, types: Array<string>) => {
    if (logging) console.log('calculating nearby places to midpoint ...')

    const params = Object.assign(midpointLocation.value.coords);
    // @ts-ignore
    // const radius = calculateDistance([locations.value["0"].coords["lng"],locations.value["0"].coords["lat"]],[midpointLocation.value.coords["lng"],midpointLocation.value.coords["lat"]])*1000
    //TODO read radius with max calculateDistance
    // const radius = 15000;
    params["radius"] = radius;
    params["types"] = types;

    addRadiusCircle(radius);

    const responsePlaces = await axios.get(baseUrl + '/nearby', {params: params})
        .then((response) => {
            if (logging) console.log('response nearby places',response.data);
            return response.data;
        })
        .catch((error) => {
            if (logging) console.error('error calculating nearby places: ', error);
        })

    places.value = {...responsePlaces}


    // add markers to the places and map
    for (let j = 0; j < responsePlaces.length; j++) {
        places.value[j]["marker"] = addLocationMarker(responsePlaces[j].geometry.location);
        places.value[j]["marker"].addListener("click", () => {
            // @ts-ignore
            clickedPlace.value["place"] = responsePlaces[j];
            // @ts-ignore
            // clickedPlace.value["key"] = j;
            getDistancesFromLocationsToClickedPlace(j);
            // TODO add distances from locations "distanceMatrix" by the i parameter
            if (logging) console.log('clicked place is',clickedPlace.value)
        });
    }
}

export const getPlaces = async (thresholdInSeconds: number) => {

    const placesObject = Object.entries(places.value);

    const locationsLength = Object.keys(locations.value).length;

    for (let i = 0; i < locationsLength; i++) {
        // @ts-ignore
        locations.value[i]["distanceMatrix"] = [];
    }

    for (let cnt = 1; cnt <= roundIfFloat(placesObject.length/20); cnt++) { //chuck distanceMatrix by 20
        if (logging) console.log(cnt, ' until ',roundIfFloat(placesObject.length/20))

        let destinationsString = '';


        // @ts-ignore
        // for (let i = (cnt-1)*20; i < cnt<=roundIfFloat(placesObject.length/20)-1 ? placesObject.length%20+cnt*20 : cnt*20; i++) {
        //@ts-ignore
        for (let i = (cnt-1)*20; i< (placesObject.length-((cnt-1)*20) > 20 ? cnt*20 : placesObject.length); i++) {
            if (logging) console.log((cnt-1)*20,'until',placesObject.length-((cnt-1)*20) > 20 ? cnt*20 : placesObject.length)
            // @ts-ignore
            if (distanceMatrixApi==='google'){
                // @ts-ignore
                destinationsString += placesObject[i][1]["geometry"]["location"]["lat"] + ',' + placesObject[i][1]["geometry"]["location"]["lng"] ;
            }else if (distanceMatrixApi==='mapbox'){
                // @ts-ignore
                destinationsString += placesObject[i][1]["geometry"]["location"]["lng"] + ',' + placesObject[i][1]["geometry"]["location"]["lat"] ;
            }

            // @ts-ignore
            if (distanceMatrixApi==='google'){
                // @ts-ignore
                destinationsString += '|';
            }else if (distanceMatrixApi==='mapbox'){
                // @ts-ignore
                destinationsString += ';';
            }

        }

        destinationsString=destinationsString.slice(0,-1);

        // getting and saving distance matrices to the locations
        for (let i = 0; i < locationsLength; i++) {
            let originString = '';
            if (distanceMatrixApi==='google'){
                // @ts-ignore
                originString = locations.value[i].coords['lat'] + ',' + locations.value[i].coords['lng'];
            }else if (distanceMatrixApi==='mapbox'){
                // @ts-ignore
                originString = locations.value[i].coords['lng'] + ',' + locations.value[i].coords['lat'];
            }
            if (logging) console.log('originString:',originString)
            if (logging) console.log('destinationsString:',destinationsString)
            const distanceMatrix = await getDistanceMatrix(originString, destinationsString);
            if (logging) console.log(distanceMatrix)

            if (logging) console.log(i)
            // @ts-ignore
            if (logging) console.log(locations.value[i]["distanceMatrix"])

            if (distanceMatrixApi==='google'){
                if (logging) console.log(distanceMatrix["rows"]["0"]["elements"])
                // @ts-ignore
                locations.value[i]["distanceMatrix"] = locations.value[i]["distanceMatrix"].concat(distanceMatrix["rows"]["0"]["elements"]);
            }else if (distanceMatrixApi==='mapbox'){
                for (let j = 0; j < distanceMatrix["destinations"].length; j++) {
                    distanceMatrix["destinations"][j]["duration"] = distanceMatrix["durations"][0][j];
                }
                distanceMatrix["destinations"].shift()
                // @ts-ignore
                locations.value[i]["distanceMatrix"] = locations.value[i]["distanceMatrix"].concat(distanceMatrix["destinations"]);
                if (logging) console.log(distanceMatrix["distanceMatrix"])
            }
        }
    }

    // todo threshold in meters
    // const thresholdInSeconds = 300; // 300seconds = 5mins
    // const thresholdInSeconds = 750; // 300seconds = 5mins
    // const thresholdInMeters = 300; // 300seconds = 5mins
    const placesLength = Object.keys(places.value).length;

    if (locationsLength<3){
        for (let i = 0; i < placesLength; i++) {
            let loc0=0;
            let loc1=0;

            if (distanceMatrixApi==='google'){
                // @ts-ignore
                loc0 = locations.value["0"]["distanceMatrix"][i]["duration"]["value"]
                // @ts-ignore
                loc1 = locations.value["1"]["distanceMatrix"][i]["duration"]["value"]
            }else if (distanceMatrixApi==='mapbox'){
                // @ts-ignore
                loc0 = locations.value["0"]["distanceMatrix"][i]["duration"]
                // @ts-ignore
                loc1 = locations.value["1"]["distanceMatrix"][i]["duration"]
            }

            if (logging) console.log(loc0 + ' vs ' + loc1)

            if (loc1 > loc0){
                if (loc0 > loc1 - thresholdInSeconds){
                    if (logging) console.log('loc0 '+loc0+' vs '+'loc1 '+loc1)
                    // @ts-ignore
                    placesWithinThreshold.value[i] = places.value[i];
                }
            }else{
                if (loc1 > loc0 - thresholdInSeconds){
                    if (logging) console.log('loc0 '+loc0+' vs '+'loc1 '+loc1)
                    // @ts-ignore
                    placesWithinThreshold.value[i] = places.value[i];
                }
            }
        }
    }

    const keysOfPlacesWithinThreshold = Object.keys(placesWithinThreshold.value);
    for (let i = 0; i < placesLength; i++) {
        // @ts-ignore
        if (logging) console.log(places.value[i]["types"][0])
        places.value[i]["marker"].setLabel({
            // @ts-ignore
            text: types_codepoints[places.value[i]["types"][0]], // codepoint from https://fonts.google.com/icons
            fontFamily: "Material Icons",
            color: "#ffffff",
            fontSize: "14px",
        })
        // @ts-ignore
        if (! keysOfPlacesWithinThreshold.includes(i.toString())){
            if (logging) console.log('removing marker',i)
            places.value[i]["marker"].visible = false;
        }
    }
}


const roundIfFloat = (num: number) => {
    if (num % 1 !== 0){ // if number is float
        return Math.floor(num)+1
    }else{
        return num;
    }
}

import {
  IonInput,
  IonItem,
  IonLabel,
  IonButton
} from "@ionic/vue";
import {getUserLocation, locations, midpointLocation, userLocation} from "@/components/map/locations";
import {
  addDirectionsPaths,
  addLocationMarker,
  addMidpointMarker,
  centerMapAtMidpoint, centerTo,
  findMiddleStep, googleMap, initMap,
  setUserLocationMarker, userLocationAccuracyCircle, userLocationMarker
} from "./map";
import {defineComponent, ref} from "vue";
import {geocodeAddress, reverseGeocode} from "@/components/map/geocode";
import {getDirections} from "@/components/map/directions";
import {getNearbyMidpoint, getPlaces, places, placesWithinThreshold} from "@/components/map/places";
import {geocodeApi} from "@/settings";
import {toggleShowResults} from "@/components/results/results";
import {radius, threshold, type} from "@/components/map/filters";
import {resetForNewSearch} from "@/components/reset";
const showLocationInputs = ref(false);
const send = async () => {
  resetForNewSearch();
  initMap();
  /* STEPS
  * 1. Geocode the inputs to get the lat and lng
  * 2. Add Markers for the input locations
  * 3. Find the Midpoint (either by LatLngs (geographic) or the midpoint of directions (travelling time))
  * 4. If midpoint by directions, first get the directions
  * 5. add the path to the map
  * 6. Find the middle of the direction path
  * 7. get places in defined radius around midpoint
  * 8. get the optimized places within the defined threshold (travel time) TODO: add distance
  *
  * */


  showLocationInputs.value=!showLocationInputs.value;
  locations.value["0"].geocoding = await geocodeAddress(locations.value[0]['userInputAddress']);
  locations.value["1"].geocoding = await geocodeAddress(locations.value[1]['userInputAddress']);

  // @ts-ignore
  if (geocodeApi==='google'){
    // @ts-ignore
    locations.value["0"].coords = locations.value["0"].geocoding['geometry']['location'];

    locations.value["0"].marker = addLocationMarker(locations.value["0"].coords);
  }
  else if (geocodeApi==='mapbox'){
    // @ts-ignore
    const latlngArray = locations.value["0"].geocoding['features'][0]["geometry"]["coordinates"];
    locations.value["0"].coords = {lng: latlngArray[0], lat: latlngArray[1]}
    // @ts-ignore
    locations.value["0"].marker = addLocationMarker(locations.value["0"].coords);
  }

  // @ts-ignore
  if (geocodeApi==='google'){
    // @ts-ignore
    locations.value["1"].coords = locations.value["1"].geocoding['geometry']['location'];
    locations.value["1"].marker = addLocationMarker(locations.value["1"].coords);
  }
  else if (geocodeApi==='mapbox'){
    // @ts-ignore
    // @ts-ignore
    const latlngArray = locations.value["1"].geocoding['features'][0]["geometry"]["coordinates"];
    locations.value["1"].coords = {lng: latlngArray[0], lat: latlngArray[1]}
    // @ts-ignore
    locations.value["1"].marker = addLocationMarker(locations.value["1"].coords);
    console.log(locations.value["1"].marker)
  }

  // midpointLocation.value.coords=calculateMidpoint();
  // addMidpointMarker(midpointLocation.value.coords);

  await getDirections();
  await addDirectionsPaths();
  await findMiddleStep();
  //
  //
  await getNearbyMidpoint(radius.value,[type.value]);
  await getPlaces(threshold.value); // 300: 5min, 600: 10min, 900: 15min
  await centerMapAtMidpoint();
  toggleShowResults();
}

const getMyLocation = async () => {
  await getUserLocation().then(()=>{
    setUserLocationMarker(userLocation.value);
    centerTo(userLocation.value);
    googleMap.value.setZoom(17);
  })

  const geocode = await reverseGeocode(userLocation.value);
  console.log('geocode',geocode)
  //@ts-ignore
  locations.value["0"].userInputAddress=geocode['features'][0]['place_name'];
}

export default defineComponent({
  name: 'AddLocations',
  components: {
    IonInput, IonItem, IonLabel, IonButton,
  },
  setup(){
    return{
      locations,
      send,
      places,
      placesWithinThreshold,
      showLocationInputs,
      getMyLocation,
    }
  }
});



import {
    IonApp,
    IonRouterOutlet,
    IonHeader,
    IonMenu,
    IonSplitPane,
    IonContent
} from '@ionic/vue';
import {computed, defineComponent} from 'vue';
import ResultsMenu from "@/components/results/ResultsMenu.vue";
import ResultsModal from "@/components/results/ResultsModal.vue";
import AddLocations from "@/components/map/AddLocations.vue";
import {placesWithinThreshold} from "@/components/map/places";
import {showSplitPane, showSplitPaneOnBiggerScreensByDefault} from "@/components/map/splitpane";
// const existResults = ref(false);
export default defineComponent({
  name: 'App',
  components: {
    // ResultsModal,
    // ResultsMenu,
    IonApp,
    IonRouterOutlet,
    // IonHeader,
    // IonMenu,
    // IonSplitPane,
    // IonContent,
    // AddLocations,
  },
  setup() {
    showSplitPaneOnBiggerScreensByDefault();
    const checkExistResults = computed(() => {
      return Object.entries(placesWithinThreshold.value).length>0;
    });

    // onMounted(()=>{
    //   //@ts-ignore
    //   existResults.value = checkExistResults();
    // });

    return {
      checkExistResults,
      showSplitPane,
    }
  }
});




import {initMap} from "@/components/map/map";

export default ({
  name: 'GoogleMap',
  components: {
  },


  setup(){
    initMap();
  }
});


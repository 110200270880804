import {resetLocations, resetMidpointLocation} from "@/components/map/locations";
import {resetDirections} from "@/components/map/directions";
import {googleMap, resetDirectionsPath, resetMiddlestPath, resetRadiusCircle} from "@/components/map/map";
import {resetClickedPlace, resetPlaces, resetPlacesWithinThreshold} from "@/components/map/places";
declare let google: any;
export const resetForNewSearch = () => {
    resetLocations();
    resetMidpointLocation();
    resetDirections();
    resetRadiusCircle();
    resetPlaces();
    resetPlacesWithinThreshold();
    resetClickedPlace();
    resetDirectionsPath();
    resetMiddlestPath();

    const centerGermany = { lat: 51.0613773, lng: 10.3487311 };
    googleMap.value = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
            zoom: 3,
            center: centerGermany,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.LEFT_BOTTOM,
            },
            zoomControl: true,
            // zoomControlOptions: {
            //     position: google.maps.ControlPosition.LEFT_CENTER,
            // },
            scaleControl: true,
            streetViewControl: true,
            // streetViewControlOptions: {
            //     position: google.maps.ControlPosition.RIGHT_BOTTOM,
            // },
            fullscreenControl: true,
        }
    );
}

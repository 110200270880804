import axios from "axios";
import {baseUrl, logging} from "@/settings";
import {locations} from "@/components/map/locations";
import {clickedPlace} from "@/components/map/places";

export const getDistanceMatrix = async (origins: string, destinations: string) => {
    return await axios.post(baseUrl + '/locations', {
        // @ts-ignore
        origins: origins,
        destinations: destinations,
    })
        .then(async response => {
            if (logging) console.log('getting distance matrix from backend', response)
            return await response.data;
        })
        .catch(e => {
            if (logging) console.log('error getting distance matrix from backend')
        });
}

export const getDistancesFromLocationsToClickedPlace = (key: number) =>{
    const locationsLength = Object.keys(locations.value).length;
    const distances = {};

    for (let i = 0; i < locationsLength; i++) {
        // @ts-ignore
        const distanceMatrix = {...locations.value[i]["distanceMatrix"]};

        // @ts-ignore
        distances[i] = distanceMatrix[key];
    }
    // @ts-ignore
    clickedPlace.value["distances"] = distances;
}
import {ref} from "vue";

export const radius = ref(10000);
export const threshold = ref(900);

export const types = {
    0: 'lodging',
    1: 'cafe',
    2: 'restaurant',
    3: 'bakery',
    4: 'meal_takeaway',
};
export const type = ref(types[1]);
import {ref} from "vue";
import { Geolocation } from '@awesome-cordova-plugins/geolocation';
import {logging} from "@/settings";

export const showSearchInputs = ref(false);
export const toggleShowSearchInputs = () => {
    showSearchInputs.value = !showSearchInputs.value;
}
export const hideSearchInputs = () => {
    showSearchInputs.value = false;
}

export const locations = ref({
    0: {
        userInputAddress: '',
        coords: {},
        geocoding: {},
        marker: {},
    },
    1: {
        userInputAddress: '',
        coords: {},
        geocoding: {},
        marker: {},
    }
});
export const userLocation = ref();
export const midpointLocation = ref({
    coords: {},
    marker: {},
});

export const resetLocations = () => {
    const locationsKeys = Object.keys(locations.value);
    for (let i = 0; i < locationsKeys.length; i++) {
        if (logging) { // @ts-ignore
            console.log('RESET FOR LOCATION '+i,locations.value[i])
        }
        // @ts-ignore
        locations.value[i].coords={};
        // @ts-ignore
        locations.value[i].geocoding={};
        // @ts-ignore
        locations.value[i].marker={};
    }
}
export const resetMidpointLocation = () => {
    midpointLocation.value.coords={};
    if (Object.keys(midpointLocation.value.marker).length !== 0){
        console.log('setting map of',midpointLocation.value.marker,'to null')
        // @ts-ignore
        midpointLocation.value.marker.setMap(null);
        // @ts-ignore
        midpointLocation.value.marker.visible = false;
    }
    midpointLocation.value.marker={};
}

export const getUserLocation = async () => {
    await Geolocation.getCurrentPosition({enableHighAccuracy: true}).then((response) => {
        userLocation.value = {
            coords: response.coords,
            lat: response.coords.latitude,
            lng: response.coords.longitude,
        };
        console.log(userLocation.value)
        // resp.coords.latitude
        // resp.coords.longitude
    }).catch((error) => {
        console.log('Error getting location', error);
    });

    // const interval = setInterval(async function () {
    //     await Geolocation.getCurrentPosition({enableHighAccuracy: true}).then((response) => {
    //         userLocation.value = {
    //             coords: response.coords,
    //             lat: response.coords.latitude,
    //             lng: response.coords.longitude,
    //         };
    //         console.log(userLocation.value.coords.accuracy)
    //         if (userLocation.value.coords.accuracy<30){
    //             clearInterval(interval);
    //         }
    //         // resp.coords.latitude
    //         // resp.coords.longitude
    //     }).catch((error) => {
    //         console.log('Error getting location', error);
    //     });
    // }, 5000);

}

// export const getMidpoint = () => {
//
// }
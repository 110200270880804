import {locations, midpointLocation} from "@/components/map/locations";

declare let google: any;

import {onMounted, ref} from "vue";
import {directionsApi, logging} from "@/settings";
// @ts-ignore
import * as turf from '@turf/turf';
import {directions} from "@/components/map/directions";
import {placesWithinThreshold} from "@/components/map/places";

export const googleMap = ref();
export const userLocationMarker = ref();
export const userLocationAccuracyCircle = ref();
export const radiusCircle = ref();
export const directionsPath = ref();
export const middlestPath = ref();

export const resetRadiusCircle = () => {
    if (radiusCircle.value !== undefined) {
        if (Object.keys(radiusCircle.value).length !== 0) {
            console.log('setting map of radius circle', radiusCircle.value, 'to null')
            // @ts-ignore

            radiusCircle.value.setVisible(false);
            radiusCircle.value.setMap(null);
            // @ts-ignore
            radiusCircle.value.visible = false;
            radiusCircle.value = {};
        }
    }
}
export const resetDirectionsPath = () => {
    if (directionsPath.value !== undefined) {
        directionsPath.value.setMap(null);
        // @ts-ignore
        directionsPath.value.visible = false;
        directionsPath.value = {};
    }
}
export const resetMiddlestPath = () => {
    if (middlestPath.value !== undefined) {
        middlestPath.value.setMap(null);
        // @ts-ignore
        middlestPath.value.visible = false;
        middlestPath.value = {};
    }
}

export const initMap = async () => {

    onMounted(async ()=>{
        // The location of Uluru
        const centerGermany = { lat: 51.0613773, lng: 10.3487311 };
        // The map, centered at Uluru
        googleMap.value = new google.maps.Map(
            document.getElementById("map") as HTMLElement,
            {
                zoom: 3,
                center: centerGermany,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.LEFT_BOTTOM,
                },
                zoomControl: true,
                // zoomControlOptions: {
                //     position: google.maps.ControlPosition.LEFT_CENTER,
                // },
                scaleControl: true,
                streetViewControl: true,
                // streetViewControlOptions: {
                //     position: google.maps.ControlPosition.RIGHT_BOTTOM,
                // },
                fullscreenControl: true,
            }
        );

        // The marker, positioned at Uluru

    })

}

export const centerTo = (coords: any) => {
    if (logging) console.log('centering map at',coords);
    googleMap.value.panTo(coords)
}

export const zoomTo = (zoom: number) => {
    googleMap.value.setZoom(zoom)
}

export const setUserLocationMarker = (coords: any) => {
    userLocationMarker.value = new google.maps.Marker({
        position: coords,
        map: googleMap.value,
    });

    userLocationAccuracyCircle.value = new google.maps.Circle({
        strokeColor: "#3959ff",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#3959ff",
        fillOpacity: 0.35,
        map: googleMap.value,
        center: coords,
        radius: coords.coords.accuracy,
    });
}

export const addRadiusCircle = (radius: number) => {
    radiusCircle.value = new google.maps.Circle({
        strokeColor: "#ff480d",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#ff480d",
        fillOpacity: 0.35,
        map: googleMap.value,
        center: midpointLocation.value.coords,
        radius: radius,
    });
}

export const addDirectionsPaths = () => {
    // const steps = Object.entries(directions.value["routes"][0]["legs"][0]["steps"]);
    //
    // for (let i = 0; i < steps.length; i++) {
    //     // @ts-ignore
    //     // console.log(steps[i][1]["start_location"])
    //     // console.log(steps[i][1]["end_location"])
    //     const path = new google.maps.Polyline({
    //         // @ts-ignore
    //         path: [steps[i][1]["start_location"],steps[i][1]["end_location"]],
    //         geodesic: true,
    //         strokeColor: "#FF0000",
    //         strokeOpacity: 1.0,
    //         strokeWeight: 2,
    //     });
    //
    //     console.log(path)
    //
    //     path.setMap(googleMap.value);
    // }
    let decodedPath = '';
    if (directionsApi==='google'){
        decodedPath = google.maps.geometry.encoding.decodePath(directions.value["routes"][0]["overview_polyline"]["points"]);

    }else if (directionsApi==='mapbox'){
        if (logging) console.log('geometry is: '+directions.value["routes"][0]["geometry"])
        console.log(google.maps.geometry)
        decodedPath = google.maps.geometry.encoding.decodePath(directions.value["routes"][0]["geometry"]);
    }

    // console.log(decodedPath)

    // const lineString = [];
    // for (const decodedPathElement of decodedPath) {
    //     lineString.push([decodedPathElement.toJSON().lng,decodedPathElement.toJSON().lat])
    //     console.log([decodedPathElement.toJSON().lng,decodedPathElement.toJSON().lat])
    // }
    directionsPath.value = new google.maps.Polyline({
        path: decodedPath,
        strokeColor: '#ff0000',
        strokeOpacity: 0.6,
        strokeWeight: 5,
        map: googleMap.value
    });

    // const line = turf.lineString(lineString);
    // const length = turf.length(line);
    // console.log('length',length)

}

/*
* Finds the middlest step and coordinates of the direction steps and returns the coords
* adds a Marker at the coords
* */
// TODO find better middle coords ON the direction
export const findMiddleStep = async () => {
    //@ts-ignore
    let steps = [] ;
    let middleDuration = 0.0;

    // @ts-ignore
    if (directionsApi==='google'){
        middleDuration = directions.value["routes"][0]["legs"][0]["duration"]["value"]/2; // half of the total traveling time
        steps = Object.entries(directions.value["routes"][0]["legs"][0]["steps"]); // the direction steps
    }
    else if (directionsApi==='mapbox'){
        middleDuration = directions.value["routes"][0]["legs"][0]["duration"]/2; // half of the total traveling time
        steps = Object.entries(directions.value["routes"][0]["legs"][0]["steps"]); // the direction steps
    }
    if (logging) console.log('middleDuration is: '+middleDuration)

    // const middleDuration = directions.value["routes"][0]["legs"][0]["duration"]["value"]/2; // half of the total traveling time
    // const steps = Object.entries(directions.value["routes"][0]["legs"][0]["steps"]); // the direction steps

    let middlestStep = {};
    let stepsDurationSum = 0;

    // iterate all the steps and add each steps accumulate each steps travelling time until the summed time would be
    // higher than half of the total duration.
    // The step that would be over the half is the middlest step.
    for (let i = 0; i < steps.length; i++) {
        let value = 0;
        if (directionsApi==='google'){
            // @ts-ignore
            value = steps[i][1]["duration"]["value"];
        }
        else if (directionsApi==='mapbox'){
            // @ts-ignore
            value = steps[i][1]["duration"];
        }
        if (logging) console.log('step: '+i+' | total duration: '+stepsDurationSum+ ' | next would be '+ (stepsDurationSum+value))

        if (stepsDurationSum+value < middleDuration){
            stepsDurationSum += value;
        }else{
            // @ts-ignore
            middlestStep = steps[i][1];
            break;
        }
    }
    if (logging) console.log('middlestStep',middlestStep)
    // @ts-ignore
    let decodedPath: any;
    let quot = 0;
    if (directionsApi==='google'){
        // @ts-ignore
        decodedPath = google.maps.geometry.encoding.decodePath(middlestStep["polyline"]["points"]);
        middlestPath.value = new google.maps.Polyline({
            path: decodedPath,
            strokeColor: '#163aff',
            strokeOpacity: 1.0,
            strokeWeight: 5,
            map: googleMap.value
        });
        // @ts-ignore
        quot = (middleDuration-stepsDurationSum)/middlestStep["duration"]["value"]; //calculate the percentage of the middlest step needed to calculate the middle point the the step
    }
    else if (directionsApi==='mapbox'){
        // @ts-ignore
        decodedPath = google.maps.geometry.encoding.decodePath(middlestStep["geometry"]);
        middlestPath.value = new google.maps.Polyline({
            path: decodedPath,
            strokeColor: '#163aff',
            strokeOpacity: 1.0,
            strokeWeight: 5,
            map: googleMap.value
        });
        // @ts-ignore
        quot = (middleDuration-stepsDurationSum)/middlestStep["duration"]; //calculate the percentage of the middlest step needed to calculate the middle point the the step
    }

    decodedPath = decodedPath.map((point: any)=>{
        return [
            point.toJSON().lat,
            point.toJSON().lng,
        ]
    })

    console.log('QUOT IS',quot)

    let from = {};
    let to = {};
    if (directionsApi==='google'){
        // @ts-ignore
        from = turf.point([middlestStep["start_location"].lat,middlestStep["start_location"].lng]);
        // @ts-ignore
        to = turf.point([middlestStep["end_location"].lat,middlestStep["end_location"].lng]);
    }else if (directionsApi==='mapbox'){
        // @ts-ignore
        const intersections = middlestStep["intersections"];
        // @ts-ignore
        from = turf.point([intersections[0]["location"][1],intersections[0]["location"][0]]);
        // @ts-ignore
        to = turf.point([intersections[intersections.length-1]["location"][1],intersections[intersections.length-1]["location"][0]]);
    }

    // @ts-ignore
    const distance = turf.distance(from, to, {units: 'kilometers'}); // get the total distance of the middle step
    if (logging) console.log('DISTANCE IS',distance)
    // @ts-ignore
    // const line = turf.lineString([[middlestStep["start_location"].lat,middlestStep["start_location"].lng], [middlestStep["end_location"].lat,middlestStep["end_location"].lng]]);
    const line = turf.lineString(decodedPath);
    if (logging) console.log('TURF LINE STRING',line)
    // const coords = line.geometry.coordinates;
    // const bearing = turf.bearing(turf.point(coords[0]),turf.point(coords[coords.length-1]))
    // if (logging) console.log('BEARING IS ',bearing)
    const chunk = turf.lineChunk(line, (distance*quot)); //chunk the line at where the middle is and save the fist chunk which is the middlest coord
    if (logging) console.log('TURF CHUNKED',chunk)

    // @ts-ignore
    let mid: any;
    // let middlestPlaceCoords = {};
    // if (directionsApi==='google'){
    //     // @ts-ignore
    //     mid = turf.along(line,(middlestStep["distance"]["value"]/1000)*quot,{units: 'kilometers'});
    //     // @ts-ignore
    //     console.log('ALONG',line,(middlestStep["distance"]["value"]/1000)*quot);
    //     // @ts-ignore
    //     console.log((middlestStep["distance"]["value"]*quot),middlestStep.distance.value)
    //     // @ts-ignore
    //     if ((middlestStep["distance"]["value"]*quot)>middlestStep.distance.value){
    //         console.log('BIGGER !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    //         // @ts-ignore
    //     }else if((middlestStep["distance"]["value"]*quot)==middlestStep.distance.value){
    //
    //         console.log('SAME !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    //         // @ts-ignore
    //         middlestPlaceCoords= middlestStep.end_location;
    //         console.log(middlestPlaceCoords)
    //     }else{
    //         middlestPlaceCoords= {lat: mid["geometry"]["coordinates"][0], lng: mid["geometry"]["coordinates"][1]};
    //     }
    // }
    // else if (directionsApi==='mapbox'){
    //     // if (bearing < -90 ){
    //     //     line.geometry.coordinates=line.geometry.coordinates.reverse()
    //     // }
    //     // @ts-ignore
    //     mid = turf.along(line,(middlestStep["distance"]/1000)*quot,{units: 'kilometers'} );
    //     // @ts-ignore
    //     console.log('ALONG',(middlestStep["distance"]/1000)*quot)
    //     middlestPlaceCoords= {lat: mid["geometry"]["coordinates"][0], lng: mid["geometry"]["coordinates"][1]};
    // }
    const coords = chunk["features"][0]["geometry"]["coordinates"];
    const middlestPlaceCoords= {lat: coords[coords.length-1][0], lng: coords[coords.length-1][1]};
    //@ts-ignore
    // console.log('MID',mid)
    console.log('middlestPlaceCoords ===',middlestPlaceCoords)


    addMidpointMarker(middlestPlaceCoords);
    midpointLocation.value.coords = middlestPlaceCoords;

    return middlestPlaceCoords;
}


export const addMidpointMarker = (coords: any) => {
    midpointLocation.value.marker = new google.maps.Marker({
        position: coords,
        map: googleMap.value,
        color: "#4df118",
        label: 'M',
    });
    googleMap.value.panTo(coords);
    googleMap.value.setZoom(14);

    return midpointLocation.value.marker;
}

export const addLocationMarker = (coords: any) => {
    return new google.maps.Marker({
        position: coords,
        map: googleMap.value,
    });
}

export const centerMapAtMidpoint = async () => {
    if (logging) console.log('starting centering at midpoint ...')
    // make the map fit all location markers
    const bounds = new google.maps.LatLngBounds();
    // const locBounds = [...Object.entries(placesWithinThreshold.value), ...Object.entries(locations.value)]
    const locBounds = [...Object.entries(placesWithinThreshold.value)]

    for (const loc of locBounds) {
        //@ts-ignore
        bounds.extend(loc[1].marker.getPosition());
    }
    googleMap.value.fitBounds(bounds);
    if (logging) console.log('map centered')


}

export const toggleHoverMarker = (place:object) =>{
    // @ts-ignore
    if (place.marker.getAnimation() !== null) {
        // @ts-ignore
        place.marker.setAnimation(null);
    } else {
        // @ts-ignore
        place.marker.setAnimation(google.maps.Animation.BOUNCE);
    }
}

export const hoverMarker = (place: object) => {
    // @ts-ignore
    place.marker.setAnimation(google.maps.Animation.BOUNCE);
}

export const cancelHoverMarker = (place: object) => {
    // @ts-ignore
    place.marker.setAnimation(null);
}

export const calculateMidpoint = () => {
    if (logging) console.log('calculating midpoint ...')

    //@ts-ignore
    const point1 = turf.point([locations.value['0'].coords.lng,locations.value['0'].coords.lat]);
    //@ts-ignore
    const point2 = turf.point([locations.value['1'].coords.lng,locations.value['1'].coords.lat]);

    const turfMidpoint = turf.midpoint(point1, point2);

    const midpoint = {lat: turfMidpoint.geometry.coordinates[1], lng: turfMidpoint.geometry.coordinates[0]}
    if (logging) console.log('midpoint is ',midpoint);

    return midpoint;
}
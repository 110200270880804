
import {IonIcon} from "@ionic/vue";
import {radius, threshold, type} from "@/components/map/filters";
import {defineComponent} from "vue";
import {homeOutline, resizeOutline, timeOutline} from "ionicons/icons";
export default defineComponent({
  name: 'FilterSettings',
  components: {
    IonIcon
  },
  setup(){
    return{
      homeOutline,
      resizeOutline,
      timeOutline,
      radius,
      threshold,
      type,
    }
  }
});

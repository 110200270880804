import {locations} from "@/components/map/locations";
import {baseUrl, directionsApi, geocodeApi, logging} from "@/settings";
import axios from "axios";
import {ref} from "vue";
export const directions = ref();

export const resetDirections = () => {
    directions.value={};
}

export const getDirections = async () => {
    //@ts-ignore
    if (directionsApi==='google'){
        let origin = '';
        let dest = '';
        if (geocodeApi==='google'){
            //@ts-ignore
            origin = locations.value['0'].geocoding.formatted_address + ',' +locations.value['0'].geocoding.formatted_address;
            //@ts-ignore
            dest = locations.value['1'].geocoding.formatted_address + ',' +locations.value['1'].geocoding.formatted_address;
        }else if (geocodeApi === 'mapbox'){
            //@ts-ignore
            const originCenter = locations.value['0'].geocoding["features"][0]["center"]
            origin = originCenter[1]+','+originCenter[0];
            //@ts-ignore
            const destCenter = locations.value['1'].geocoding["features"][0]["center"]
            dest = destCenter[1]+','+destCenter[0];
        }


        console.log('directions origin',origin)
        if (logging) console.log('getting directions ...');
        await axios.get(baseUrl + '/directions?origin=' + encodeURIComponent(origin) + '&destination='+encodeURIComponent(dest))
            .then((response) => {
                if (logging) console.log('directions are',response.data);
                directions.value = response.data;
            })
            .catch((error) => {
                if (logging) console.error('error getting directions', error);
            })
    }else if (directionsApi==='mapbox'){
        // origin = longtitude,latitude
        //@ts-ignore
        const originCenter = locations.value['0'].geocoding["features"][0]["center"]
        const origin = originCenter[0]+','+originCenter[1];
        //@ts-ignore
        const destCenter = locations.value['1'].geocoding["features"][0]["center"]
        const dest = destCenter[0]+','+destCenter[1];

        if (logging) console.log('getting directions ...');
        await axios.get(baseUrl + '/directions?origin=' + encodeURIComponent(origin) + '&destination='+encodeURIComponent(dest))
            .then((response) => {
                if (logging) console.log('directions are',response.data);
                directions.value = response.data;
            })
            .catch((error) => {
                if (logging) console.error('error getting directions', error);
            })
    }
    //@ts-ignore

}

import { defineComponent } from 'vue';
import {locations} from "@/components/map/locations";
import {baseUrl} from "@/settings";
import {hoverMarker, cancelHoverMarker, toggleHoverMarker, centerTo, zoomTo} from "@/components/map/map";
import {clickedPlace, placesWithinThreshold} from "@/components/map/places";
import {getDistancesFromLocationsToClickedPlace} from "@/components/map/distances";
import FilterSettings from "@/components/filters/FilterSettings.vue";

const getKey = (object: object) => {
  //@ts-ignore
  return Object.keys(placesWithinThreshold.value).find(key => placesWithinThreshold.value[key] === object)
}
const clickPlace = (place: object) => {

  //@ts-ignore
  clickedPlace.value["place"] = place;
  //@ts-ignore
  console.log(clickedPlace.value["place"]["name"])
  console.log(clickedPlace.value["place"])
  //@ts-ignore
  getDistancesFromLocationsToClickedPlace(getKey(place));
  zoomTo(17);
  //@ts-ignore
  centerTo(place["geometry"]["location"]);
  toggleHoverMarker(place);
}

const hasPhotos = (place: object) => {
  //@ts-ignore
  return 'photos' in place
}

const getPhotosUrl = (place: object) => {
  //@ts-ignore
  // console.log(baseUrl+'/photo?reference='+place['photos'][0]['photo_reference']+'&width='+'200'+'&id='+place['place_id'])
  //@ts-ignore
  // console.log(place['photos'])
  //@ts-ignore
  return baseUrl+'/photo?reference='+place['photos'][0]['photo_reference']+'&width='+'200'+'&id='+place['place_id'];
}

const makeGoogleMapsLink = (place: object) => {
  //@ts-ignore
  const lat = place['geometry']['location']['lat'];
  //@ts-ignore
  const lng = place['geometry']['location']['lng'];
  //@ts-ignore
  const id = place['place_id'];

  return 'https://www.google.com/maps/search/?api=1&query='+encodeURIComponent(lat+','+lng)+'&query_place_id='+encodeURIComponent(id);
}

const formatSecondsToMinutes = (seconds: number) => {
  let string = '';

  const numdays = Math.floor((seconds % 31536000) / 86400);
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  // const numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;


  if (numdays>0){
    string+=numdays + " days ";
  }
  if (numhours>0){
    string+=numhours + " hours ";
  }
  return  string+= numminutes + " minutes ";
}

export default defineComponent({
  name: 'ResultsMenu',
  components: {
    FilterSettings
  },
  setup(){
    return{
      clickedPlace,
      locations,
      placesWithinThreshold,
      getKey,
      clickPlace,
      baseUrl,
      hasPhotos,
      getPhotosUrl,
      hoverMarker,
      cancelHoverMarker,
      toggleHoverMarker,
      makeGoogleMapsLink,
      formatSecondsToMinutes,
    }
  }
});

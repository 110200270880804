
import {searchOutline, timeOutline} from "ionicons/icons";
import {IonButton, IonButtons, IonContent, IonIcon, IonMenuToggle, IonPage,} from '@ionic/vue';
import { defineComponent } from 'vue';
import GoogleMap from "@/components/map/GoogleMap.vue";
// import AddLocations from "@/components/map/AddLocations.vue";
import {locations, showSearchInputs, toggleShowSearchInputs} from "@/components/map/locations";
import {clickedPlace} from "@/components/map/places";
import {showSplitPane} from "@/components/map/splitpane";
import AddLocations from "@/components/map/AddLocations.vue";
import {showResults, toggleShowResults} from "@/components/results/results";
import ResultsMenu from "@/components/results/ResultsMenu.vue";
import {radiusCircle} from "@/components/map/map";


export default defineComponent({
  name: 'HomePage',
  components: {
    ResultsMenu,
    AddLocations,
    GoogleMap,
    // AddLocations,
    IonContent,
    IonPage,
    IonIcon,
  },
  setup(){
    return {
      clickedPlace,
      locations,
      showSplitPane,
      searchOutline,
      timeOutline,
      showSearchInputs,
      toggleShowSearchInputs,
      showResults,
      toggleShowResults,
      radiusCircle
    }
  }
});
